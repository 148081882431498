import {useEffect, useState} from 'react';
import useSWRMutation from 'swr/mutation';
import {client} from '../server/api';

const useTracker = () => {
  const [userActive, setUserActive] = useState(false); // Track user activity

  const {data, trigger} = useSWRMutation(
    ['session'],
    () => client.customer.setSession.mutate(),
    {
      onSuccess: () => setUserActive(false),
    }
  );

  useEffect(() => {
    let saveInterval: NodeJS.Timeout;
    const startSavingSession = () => {
      trigger();

      saveInterval = setInterval(() => {
        if (userActive) trigger();
      }, 120000);
    };

    const handleInteraction = () => {
      if (!data) {
        startSavingSession();
      }
      setUserActive(true);
    };

    const userAgent = navigator.userAgent;
    const isBot = /bot|crawl|spider|slurp/i.test(userAgent);

    if (!isBot) {
      window.addEventListener('click', handleInteraction);
      //  window.addEventListener('scroll', handleInteraction);
    }

    return () => {
      clearInterval(saveInterval);
      window.removeEventListener('click', handleInteraction);
      //  window.removeEventListener('scroll', handleInteraction);
    };
  }, [data, trigger, userActive]);
};

export default useTracker;
